import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "swiper/css";
import "swiper/swiper-bundle.css";
const HeroBanner = () => {
    SwiperCore.use([Autoplay]);
    return (
            <section id="welcome" className="hero d-flex align-items-center">
                <div className="container">
                    <div className="row banner align-items-center">
                        <div className="col-lg-6">
                            <h1>
                                Introducing <span className="mossai-text">Moss.ai</span>: Revolutionizing healthcare documentation              
                            </h1>
                            <div className="download-button d-none d-md-block">
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.audio.mossai&pcampaignid=web_share"
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    <img
                                        src="assets/img/google-play-store.png"
                                        alt="Google Play Store"
                                        />
                                </a>
                                <a
                                    href="https://www.apple.com/in/app-store/"
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    <img
                                        src="assets/img/apple-play-store.png"
                                        alt="Apple Play Store"
                                        />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center video-block">
                            <img src="assets/img/Simplibot_homepage.jpg" alt="Home page" style={{width:"60%",marginLeft: "auto",marginRight: "auto"}}/>                            
                        </div>
                        <div className="download-button d-md-none d-col-12">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.audio.mossai&pcampaignid=web_share"
                                target="_blank"
                                rel="noreferrer"
                                >
                                <img
                                    src="assets/img/google-play-store.png"
                                    alt="Google Play Store"
                                    />
                            </a>
                            <a
                                href="https://www.apple.com/in/app-store/"
                                target="_blank"
                                rel="noreferrer"
                                >
                                <img
                                    src="assets/img/apple-play-store.png"
                                    alt="Apple Play Store"
                                    />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            );
};

export default HeroBanner;
